
<template>
  <div
    class="sidebar"
    :class="{ 'is-close': !isOpen }"
    :style="{width: sidebarWidth + 'px'}"
  >
    <div class="header">
      <div class="logo">
        <img
          v-if="$store.getters.isLogin && $store.getters.userInfo.orgId != null && $store.getters.userInfo.role !=='3' && $store.getters.orgInfo.logoPath!= null"
          :src="resourceUrl + $store.getters.orgInfo.logoPath"
          alt="logo"
        >
        <img
          v-else
          src="@/assets/layout/天堰科技logo.png"
          alt="logo"
        >
        <div
          v-show="isOpen"
          class="title"
        >
          <div class="title-zh">{{ ($store.getters.isLogin && $store.getters.userInfo.orgId != null)? $store.getters.orgInfo.orgName : '天堰科技' }}</div>
        </div>
      </div>
      <div
        class="toggle"
        @click="toggleChange"
      >
        <i
          v-show="isOpen"
          class="el-icon-s-fold"
        />
        <i
          v-show="!isOpen"
          class="el-icon-s-unfold"
        />
      </div>
    </div>
    <div class="menu-content">
      <template v-if="userRole !== '4'">
        <div class="course-container">
          <div class="course-header">
            <a class="my-book-shelf">
              <img
                src="@/assets/comm/我的书架.png"
                alt=""
              >
              <span>{{ isOpen? '我的书架':'我的' }}</span>
            </a>
            <a
              class="all-course"
              @click="$refs.allCourseRef.openDialog()"
            >{{ isOpen? '全部课程':'全部' }}</a>
          </div>
          <div class="course-list">
            <template v-for="item in myBooks">
              <div
                v-if="item.checked"
                :key="item.courseId"
                class="item-course"
                :class="{ active: courseId === item.courseId }"
                @click="openStudyPage(item)"
              >
                <div class="img-container">
                  <img
                    v-if="item.imgPath == null || item.imgPath === ''"
                    src="@/assets/home/样例书1.png"
                    alt=""
                  >
                  <img
                    v-else
                    :src="resourceUrl + item.imgPath"
                    alt=""
                  >
                  <div
                    v-if="!item.show"
                    class="lock"
                  >
                    <i class="el-icon-lock" />
                  </div>
                </div>
                <div class="course-name">{{ item.courseName }}</div>
              </div>

            </template>
          </div>
        </div>
        <div class="line" />
        <div
          v-if="userRole !== '4'"
          class="practice-container"
        >
          <div class="practice-title">
            <img
              src="@/assets/comm/练习平台.png"
              alt=""
            >
            <span>练习平台</span>
          </div>
          <div
            class="item-practice"
            @click="openVirtualPracticePage"
          >{{ isOpen? '虚拟技能实践':'虚拟技能' }}</div>
          <!--        <div class="item-practice">{{ isOpen? '虚拟案例实践':'虚拟案例' }}</div>-->
        </div>
      </template>
    </div>
    <div class="bottom-container">
      <!--      只有在角色是1教师 或者 管理员3才显示工具栏-->
      <div
        v-if="(userRole === '1' || userRole === '3') && sidebarWidth===220"
        class="tools-container"
      >
        <div
          class="tool-item"
          style="width: 100%"
        >
          <el-popover
            ref="popoverStaticsRef"
            placement="top"
            width="160"
            trigger="click"
            :append-to-body="false"
            popper-class="my-popover"
          >
            <div class="popover-statics-content">
              <div
                v-for="(item,index) in ['成长曲线','学习记录','训练统计','资源统计']"
                :key="item"
                class="txt"
                @click="statisticClick(index)"
              >{{ item }}</div>
            </div>
            <div
              slot="reference"
              class="item-tool"
              @click="openStatisticsPage"
            >
              <img
                src="@/assets/layout/统计分析.png"
                alt=""
              >
            </div>
          </el-popover>
        </div>
        <div
          class="tool-item"
          style="width: 100%"
        >
          <div
            class="item-tool"
            @click="openCoursePage"
          >
            <img
              src="@/assets/layout/课程管理.png"
              alt=""
            >
          </div>
        </div>
        <div
          v-if="userRole === '1' && ($store.getters.userInfo.deptId == null || $store.getters.userInfo.deptId === '')"
          class="tool-item"
          style="width: 100%"
        >
          <div

            class="item-tool"
            @click="openUserPage"
          >
            <img
              src="@/assets/layout/用户管理.png"
              alt=""
            >
          </div>
        </div>

        <!--管理员显示：组织管理，用户管理，系统设置-->
        <div
          v-if="userRole === '3'"
          style="width: 100%"
          class="tool-item"
        >
          <el-popover
            ref="systemSet"
            placement="top"
            width="160"
            trigger="click"
            :append-to-body="false"
            popper-class="my-popover"
          >
            <div class="popover-statics-content">
              <div
                class="txt"
                @click="openOrganizationPage"
              >组织管理</div>
              <div
                class="txt"
                @click="openUserPage"
              >用户管理</div>
              <div
                class="txt"
                @click="openSystem"
              >系统设置</div>
            </div>
            <div
              slot="reference"
              class="item-tool"
            >
              <img
                src="@/assets/layout/设置.png"
              >
            </div>
          </el-popover>

        </div>
      </div>
      <div
        v-show="isOpen&&userRole !== '4'"
        class="project-name"
      >虚拟仿真智慧学习平台2.0</div>
      <div
        class="footer"
        :style="{justifyContent:isOpen?'space-between':'center'}"
      >
        <template v-if="!userRole">
          <div
            class="footer-left"
            @click="openLoginPage"
          >登录</div>
          <div
            v-show="isOpen"
            class="footer-right"
          >
            <img
              src="@/assets/layout/登录.png"
              alt="登录"
              @click="openLoginPage"
            >
          </div>
        </template>
        <template v-else>
          <el-tooltip
            effect="dark"
            :content="userInfo.fullName"
            placement="top-end"
            :disabled="isShowTooltip"
          >
            <div
              v-if="isOpen"
              class="user-name"
              @mouseover="onMouseOver(`你好，${userInfo.fullName}`)"
            >
              <span :ref="`你好，${userInfo.fullName}`">你好，{{ userInfo.fullName }}</span>
            </div>
          </el-tooltip>
          <el-popover
            ref="popoverRef"
            placement="bottom"
            width="160"
            trigger="click"
            :append-to-body="false"
            popper-class="my-popover"
          >
            <div class="popover-content">
              <div
                class="txt"
                @click="accountClick(1)"
              >个人资料</div>
              <div
                class="txt"
                @click="accountClick(2)"
              >修改密码</div>
              <div
                class="txt"
                @click="aboutClick"
              >关于</div>
              <div
                class="txt"
                @click="logoutClick"
              >退出</div>
            </div>
            <img
              slot="reference"
              class="avatar"
              src="@/assets/layout/用户头像.png"
            >
          </el-popover>
        </template>
      </div>
    </div>
    <all-course
      ref="allCourseRef"
      :all-course="allCourse"
    />
    <about-dialog ref="aboutRef" />
    <!--账号设置弹框-->
    <el-dialog
      class="account-dialog"
      :visible.sync="accountVisible"
      width="600px"
      :title="accountSetType===1 ? '个人资料设置' : '密码设置'"
      :destroy-on-close="true"
      @close="accountVisible=false"
    >
      <div class="account-form">
        <el-form
          ref="accountForm"
          :rules="setRules"
          :model="accountFormData"
        >
          <div
            v-if="accountSetType===1"
            key="1"
          >
            <el-form-item>
              <span class="label">用户名</span><span class="colon">:</span>
              <el-input
                v-model="accountFormData.userName"
                :disabled="true"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <div class="label">姓名</div><span class="colon">:</span>
              <el-input
                v-model="accountFormData.name"
                maxlength="100"
                clearable
              />
            </el-form-item>
            <el-form-item prop="cellNumber">
              <span class="label">手机号</span><span class="colon">:</span>
              <el-input
                v-model="accountFormData.cellNumber"
                maxlength="11"
                clearable
              />
            </el-form-item>
          </div>
          <div
            v-else
            key="2"
          >
            <el-form-item prop="currentPassword">
              <span class="labelw">当前密码</span><span class="colon">:</span>
              <el-input
                v-model="accountFormData.currentPassword"
                maxlength="50"
                type="password"
                clearable
              />
            </el-form-item>
            <el-form-item prop="newPassword">
              <span class="labelw">新密码</span><span class="colon">:</span>
              <el-input
                v-model="accountFormData.newPassword"
                maxlength="50"
                type="password"
                clearable
              />
            </el-form-item>
            <el-form-item
              prop="confirmPassword"
            >
              <span class="labelw">确定新密码</span><span class="colon">:</span>
              <el-input
                v-model="accountFormData.confirmPassword"
                maxlength="50"
                type="password"
                clearable
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <slot name="bottom">
          <button
            class="btn-info"
            @click="accountVisible = false"
          >取消</button>
          <button
            class="btn-primary"
            @click="saveAccount"
          >保存</button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AllCourse from '@/components/AllCourse'
import Course from '@/api/course'
import AboutDialog from '@/components/aboutDialog'
import config from '@/config'
import User from '@/api/user'
export default {
  name: 'SideBar',
  components: {
    AboutDialog,
    AllCourse
  },
  data() {
    return {
      isShowTooltip: false,
      accountSetType: 0, // 账号设置 1个人资料，2修改密码
      setRules: {
        cellNumber: [
          {
            validator: (rule, value, callback) => {
              const regex = /^1[3-9]\d{9}$/
              if (regex.test(value) || value === '') {
                return callback()
              } else {
                return callback(new Error('请输入正确手机号'))
              }
            },
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === this.accountFormData.newPassword) {
                return callback()
              } else {
                return callback(new Error('两次密码输入不一致'))
              }
            },
            trigger: 'blur'
          }
        ],
        currentPassword: [{ required: true, message: '请输入当前密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '新密码不能为空', trigger: 'blur' }]
      },
      accountFormData: {
        name: this.$store.getters.userInfo.fullName,
        userName: this.$store.getters.userInfo.userName,
        cellNumber: this.$store.getters.userInfo.phone,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      accountVisible: false,
      resourceUrl: config.VUE_APP_RESOURCE_URL,
      isOpen: true,
      sidebarWidth: 220,
      activeMenu: '学习平台',
      courseId: '', // 当前书架id
      allCourse: [], // 全部课程
      myBooks: [] // 我的书架
    }
  },
  computed: {
    userInfo() {
      console.log(this.$store.getters.userInfo)
      return this.$store.getters.userInfo
    },
    userRole() {
      return this.$store.getters.userInfo.role // 用户信息 role角色;1：教师；2：学生；3：天堰管理员
    }
  },
  created() {
    this.init()
    const user = JSON.parse(sessionStorage.getItem('userInfo'))
    if (user) {
      this.$store.commit('SET_USERINFO', user)
    }
    // console.log('this.$store.getters.userInfo', this.$store.getters.userInfo)
  },
  methods: {
    onMouseOver(str) { // 内容超出，显示文字提示内容
      const tag = this.$refs[str]
      const parentWidth = tag.parentElement.offsetWidth // 获取元素父级可视宽度
      const contentWidth = tag.offsetWidth // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth
    },
    statisticClick(index) {
      if (index === 0) {
        this.$router.replace('/statisticAnalysis')
      }
      if (index === 1) {
        this.$router.replace('/studyRecordCurve')
      }
      if (index === 2) {
        this.$router.replace('/exercise')
      }
      if (index === 3) {
        this.$router.replace('/resourceStatistic')
      }
      this.$refs.popoverStaticsRef.doClose()
    },
    aboutClick() {
      this.$refs.aboutRef.openDialog()
      this.$refs.popoverRef.doClose()
    },
    accountClick(type) {
      this.accountSetType = type
      this.accountVisible = true
      if (type === 1) {
        this.accountFormData.name = this.$store.getters.userInfo.fullName
        this.accountFormData.userName = this.$store.getters.userInfo.userName
        this.accountFormData.cellNumber = this.$store.getters.userInfo.phone
      } else {
        this.accountFormData.confirmPassword = ''
        this.accountFormData.newPassword = ''
        this.accountFormData.currentPassword = ''
      }
    },
    /**
     * 保存个人资料/密码
     */
    async saveAccount() {
      if (this.accountSetType === 1) {
        this.$refs['accountForm'].validate(async(valid) => {
          if (valid) {
            const res = await User.editUser({
              deptId: this.$store.getters.userInfo.deptId,
              fullName: this.accountFormData.name,
              orgId: this.$store.getters.userInfo.orgId,
              phone: this.accountFormData.cellNumber,
              role: this.$store.getters.userInfo.role,
              userId: this.$store.getters.userInfo.userId,
              userName: this.accountFormData.userName
            })
            if (res.ok) {
              this.$store.commit('SET_USERINFO', res.data)
              this.accountVisible = false
            } else {
              this.$message.error(res.message)
            }
          }
        })
      } else {
        this.$refs['accountForm'].validate(async(valid) => {
          if (valid) {
            const res = await User.editUserPassWord({
              newPassWord: this.accountFormData.newPassword,
              oldPassWord: this.accountFormData.currentPassword,
              userId: this.$store.getters.userInfo.userId
            })
            if (res.ok) {
              await this.logoutClick()
              this.accountVisible = false
            } else {
              this.$message.error(res.message)
            }
          }
        })
      }
    },
    logoutClick() {
      window.history.replaceState(null, '', '/')
      this.$router.push('/home/index')
      this.$store.commit('LOGOUT')
      window.location.reload()
      this.$router.replace('/home/index')
    },
    async init() {
      if (this.userRole === '4') {
        this.toggleChange()
      }
      // 判断是否登录  未登录查询全部课程
      if (!this.$store.getters.isLogin) {
        const res = await Course.queryCourseList()
        if (res.ok) {
          // 只保留一本书，把其他书置灰
          res.data.forEach((item, index) => {
            if (index === 0) {
              item.show = true
              item.checked = true
            } else {
              item.show = false
              item.checked = true
            }
          })
          this.myBooks = res.data
          this.allCourse = res.data
          this.courseId = res.data.length > 0 ? res.data[0].courseId : ''
          this.$store.commit('SET_COURSE_ID', this.courseId)
        }
      } else {
        // 判单角色，如果角色为4
        // 查询全部课程
        const res = await Course.queryCourseList()
        const courseList = res.data
        let orgRes
        let orgCourseIds
        if (this.$store.getters.userInfo.role === '4') {
          orgRes = await Course.queryUserCourseList({ userId: this.$store.getters.userInfo.userId })
          orgCourseIds = orgRes.data.map(item => item.courseId)
        } else {
          // 查询机构下所有课程
          orgRes = await Course.queryOrgCourseList({ orgId: this.$store.getters.orgInfo.orgId })
          orgCourseIds = orgRes.data.map(item => item.courseId)
        }
        // 查询我的书架课程
        const resBook = await Course.queryMyBookshelf({ userId: this.$store.getters.userInfo.userId })
        const bookListIds = resBook.data.map(item => item.courseId)

        courseList.forEach(item => {
          item.show = !!orgCourseIds.includes(item.courseId)
          item.checked = !bookListIds.includes(item.courseId)
        })
        console.log(courseList)
        // 排序
        courseList.sort((a, b) => {
          if (a.show && !b.show) {
            return -1
          } else if (!a.show && b.show) {
            return 1
          } else {
            return 0
          }
        })
        this.myBooks = courseList
        this.allCourse = courseList
        this.courseId = this.myBooks.length > 0 ? this.myBooks[0].courseId : ''
        this.$store.commit('SET_COURSE_ID', this.courseId)
      }
      // this.openStudyPage(this.courseId)
    },
    toggleChange() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.sidebarWidth = 220
      } else {
        this.sidebarWidth = 120
      }
      this.$emit('toggleChange', this.isOpen)
    },
    openLoginPage() {
      this.$store.commit('OPEN_LOGIN')
    },
    openStudyPage(item) {
      if (!item.show) {
        this.$message.info('该课程无权限查看，请联系管理员解锁')
        return
      }
      this.activeMenu = '学习平台'
      this.courseId = item.courseId
      this.$store.commit('SET_COURSE_ID', this.courseId)
      this.$router.push({ path: '/home/index' })
    },
    openStatisticsPage() {
      // this.activeMenu = '统计分析'
      // this.$router.push('/statisticAnalysis')
    },
    openCoursePage() {
      this.activeMenu = '课程管理'
      if (this.userInfo.role === '1') {
        this.$router.push('/courseManage/teacherCourseManage')
      } else if (this.userInfo.role === '3') {
        this.$router.push('/courseManage')
      }
    },
    openOrganizationPage() {
      this.activeMenu = '组织管理'
      this.$router.push('/organizationManage')
      this.$refs.systemSet.doClose()
    },
    openUserPage() {
      this.activeMenu = '用户管理'
      if (this.userInfo.role === '1') {
        this.$router.push('/userManage')
      } else if (this.userInfo.role === '3') {
        this.$router.push('/userManage/superUser')
      }
      this.$refs.systemSet.doClose()
    },
    openSystem() {
      this.$router.push('/systemSet/journalism')
      this.$refs.systemSet.doClose()
    },
    openVirtualPracticePage() {
      this.$router.push('/virtualPractice')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar{
  position: relative;
  width: 100px;
  height: 100%;
  background: #FFFFFF;
  //transition: all .2s ease-in-out;
  border-right: 1px solid #EAEAEA;
  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 94px;
    border-bottom: 1px solid #EAEAEA;
    .logo{
      height: 45px;
      display: flex;
      align-items: center;
      img{
        height: 45px;
        margin-right: 10px;
      }
      .title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 120px;
        height: 45px;
        .title-zh{
          font-size: 22px;
          letter-spacing: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        .title-en{
          font-size: 12px;
          letter-spacing: 0.1px;
          zoom: .8;
        }
      }
    }
    .toggle{
      width: 24px;
      height: 24px;
      background: #F1F5FB;
      border-radius: 1.8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover{
        background: #DFE4EB;
      }
    }
  }
  .menu-content{
    .course-container{
      width: 100%;
      .course-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px;
        .my-book-shelf{
          display: flex;
          align-items: center;
          img {
            width: 18px;
            height: 18px;
          }
          span{
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: #A8A8A8;
            font-weight: 500;
            margin-left: 2px;
          }
        }
        .all-course{
          font-family: PingFangSC-Medium;
          font-size: 16px;
          color: #0067FF;
          font-weight: 500;
          &:hover{
            color: #00379F;
          }
        }
      }
      .course-list{
        padding: 0 8px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        max-height: calc(100vh - 500px);
        overflow-x: hidden;
        &::-webkit-scrollbar{
          width: 3px;
        }
        .item-course{
          margin: 8px;
          cursor: pointer;
          .img-container{
            position: relative;
            height: 84px;
            width: 84px;
            background: #F1F5FB;
            border-radius: 4px;
            padding: 8px 0;
            text-align: center;
            img{
              height: 100%;
            }
            .lock{
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              opacity: .3;
              border-radius: 4px;
              background: #000;
              content: "el-icon-lock";
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              i{
                font-size: 22px;
              }
            }
          }
          .course-name{
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #000000;
            font-weight: 400;
            text-align: center;
            padding: 5px 0;
            margin-top: 5px;
            width: 84px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .active{
          .img-container{
            background: #0067FF;
          }
          .course-name{
            color: #0067FF;
          }
        }
      }
    }
    .line{
      height: 1px;
      background: #EAEAEA;
      width: calc(100% - 30px);
      margin: 10px auto;
    }
    .practice-container{
      width: 100%;
      padding: 5px 16px;
      .practice-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
        img{
          height: 18px;
          width: 18px;
          margin-right: 5px;
        }
        span{
          font-family: PingFangSC-Medium;
          font-size: 16px;
          color: #A8A8A8;
          line-height: 20px;
          font-weight: 500;
        }
      }
      .item-practice{
        cursor: pointer;
        width: 100%;
        height: 52px;
        background: #F1F5FB;
        border-radius: 4px;
        line-height: 52px;
        text-align: center;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #000000;
        font-weight: 400;
        margin-bottom: 10px;
        &:hover{
          background: #DFE4EB;
        }
        &:active{
          background: #D1D6DE;
          //color: #fff;
        }
      }
      .active-item-practice{
        background: #0067FF;
        color: #fff;
      }
    }
  }
  .bottom-container{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    .tools-container{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px 0 16px;
      /deep/ .my-popover{
        padding: 0;
      }
      .popover-statics-content{
        .txt{
          font-size: 16px;
          color: #000000;
          line-height: 55px;
          width: 100%;
          text-align: center;
          border-bottom: 1px solid #EAEAEA;
          cursor: pointer;
          &:hover{
            background: #F2F5FB;
          }
          &:nth-last-child(1){
            border: none;
          }
        }
      }
      .tool-item{
        margin-right: 10px;
        &:last-child{
          margin-right: 0;
        }
      }
      .item-tool{
        cursor: pointer;
        min-width: 40px;
        height: 40px;
        background: #F1F5FB;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          height: 20px;
        }
        &:hover{
          background: #DFE4EB;
        }
      }

    }
    .project-name{
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: #000000;
      font-weight: 500;
      text-align: center;
      padding: 20px 0;
    }
    .footer{
      height: 72px;
      border-top: 1px solid #EAEAEA;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .footer-left{
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #000000;
        line-height: 20px;
        font-weight: 500;
        cursor: pointer;
        &:hover{
          color: #0067FF;
        }
      }
      .footer-right{
        cursor: pointer;
        img{
          width: 18px;
          height: 18px;
        }
      }
      .user-name{
        font-size: 16px;
        color: #000000;
        font-weight: 500;
        width: calc(100% - 40px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .avatar{
        width: 36px;
        cursor: pointer;
      }
      /deep/ .my-popover{
        padding: 0;
      }
      .popover-content{
        .txt{
          width: 100%;
          height: 54px;
          text-align: center;
          font-size: 16px;
          color: #000000;
          line-height: 54px;
          cursor: pointer;
          border-bottom: 1px solid  #EAEAEA;
          &:hover{
            background: #F2F5FB;
          }
        }
        .txt:nth-child(4){
          color: #E32E1E;
          border-bottom: none;
        }
      }
    }
  }
}
.is-close{
  .header{
    justify-content: space-between;
    padding: 0 10px;
  }
  .bottom-container{
    .tools-container{
      flex-wrap: wrap;
      margin-bottom: 10px;
      margin-top: -10px;
      .item-tool{
        width: 40px;
        margin-right: 0;
        margin-top: 8px;
      }
    }
  }
}
.account-dialog{
  ::v-deep .el-dialog{
    border-radius: 8px;
  }
  ::v-deep .el-icon-close{
    font-size: 32px;
    color: #000000;
  }
  ::v-deep .el-dialog__body{
    border-top: 1px solid #EBEBEB;
  }
  ::v-deep .el-form-item__content{
    display: flex;
  }
  .account-form{
    padding: 0 10px;
    height: 250px;
    .label,.labelw{
      width: 85px;
      display: inline-block;
      text-align: justify;
      text-align-last: justify;
      font-size: 16px;
    }
    .labelw{
      width: 105px;
    }
    .colon{
      margin-right: 12px;
    }
    .title{
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #000000;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    .btn-info{
      margin-right: 15px;
    }
  }
}
::v-deep .el-form-item__error {
  left: 100px;
}
</style>
