import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import store from '@/store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/home/components/DcmView2.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import('@/views/Demo.vue')
  },
  {
    path: '/file',
    name: 'ViewFile',
    component: () => import('@/components/ViewFile')
  },
  {
    path: '/home',
    name: 'home',
    component: Layout,
    meta: { title: '学习平台' },
    children: [
      {
        path: 'index',
        name: 'index', // 首页
        component: () => import('@/views/home/index.vue')
      },
      {
        path: 'run',
        name: 'run', // 运行
        component: () => import('@/views/home/run.vue')
      }]
  },
  {
    path: '/courseManage',
    name: 'CourseManage',
    redirect: '/courseManage/index',
    component: Layout,
    meta: { title: '课程管理' },
    children: [
      {
        path: 'index',
        meta: { stu: 0, teacher: 0 },
        component: () => import('@/views/courseManage/index.vue')
      },
      {
        path: 'edit',
        meta: { stu: 0, teacher: 0 },
        component: () => import('@/views/courseManage/edit.vue')
      }, {
        path: 'taskEdit',
        meta: { stu: 0 },
        component: () => import('@/views/courseManage/taskEdit.vue')
      }, {
        path: 'teacherCourseManage',
        meta: { stu: 0 },
        component: () => import('@/views/courseManage/teacherCourseManage.vue')
      }]
  },
  {
    path: '/organizationManage',
    name: 'OrganizationManage',
    redirect: '/organizationManage/index',
    component: Layout,
    meta: { title: '组织管理' },
    children: [
      {
        path: 'index',
        meta: { stu: 0, teacher: 0 },
        component: () => import('@/views/organizationManage/index.vue')
      },
      {
        path: 'create',
        meta: { stu: 0, teacher: 0 },
        component: () => import('@/views/organizationManage/createOrganization.vue')
      }]
  },
  {
    path: '/userManage',
    name: 'userManage',
    redirect: '/userManage/index',
    component: Layout,
    meta: { title: '用户管理' },
    children: [
      {
        path: 'index',
        meta: { stu: 0 },
        component: () => import('@/views/userManage/index.vue')
      },
      {
        path: 'superUser',
        meta: { stu: 0, teacher: 0 },
        component: () => import('@/views/userManage/superUser.vue')
      }]
  },
  {
    path: '/statisticAnalysis',
    name: 'StatisticAnalysis',
    redirect: '/statisticAnalysis/index',
    component: Layout,
    meta: { title: '统计分析-成长曲线' },
    children: [
      {
        path: 'index',
        meta: { stu: 0 },
        component: () => import('@/views/statisticAnalysis/index.vue')
      }]
  },
  {
    path: '/virtualPractice',
    name: 'VirtualPractice',
    redirect: '/virtualPractice/index',
    component: Layout,
    meta: { title: '虚拟技能实践' },
    children: [
      {
        path: 'index',
        component: () => import('@/views/home/virtualPractice.vue')
      }]
  },
  {
    path: '/studyRecord',
    name: 'StudyRecord',
    redirect: '/studyRecord/index',
    component: Layout,
    meta: { title: '学习记录' },
    children: [
      {
        path: 'index',
        component: () => import('@/views/home/studyRecord.vue')
      }]
  },
  {
    path: '/resourceStatistic',
    name: 'ResourceStatistic',
    redirect: '/resourceStatistic/index',
    component: Layout,
    meta: { title: '统计分析-资源统计' },
    children: [
      {
        path: 'index',
        meta: { stu: 0 },
        component: () => import('@/views/statisticAnalysis/resourceStatistic.vue')
      }]
  },
  {
    path: '/studyRecordCurve',
    name: 'StudyRecordCurve',
    redirect: '/studyRecordCurve/index',
    component: Layout,
    meta: { title: '统计分析-学习记录' },
    children: [
      {
        path: 'index',
        meta: { stu: 0 },
        component: () => import('@/views/statisticAnalysis/studyRecordCurve.vue')
      }]
  },
  {
    path: '/growthCurve',
    name: 'GrowthCurve',
    redirect: '/growthCurve/index',
    component: Layout,
    meta: { title: '成长曲线' },
    children: [
      {
        path: 'index',
        component: () => import('@/views/statisticAnalysis/GrowthCurve.vue')
      }]
  },
  {
    path: '/studyNote',
    name: 'StudyNote',
    redirect: '/studyNote/index',
    component: Layout,
    meta: { title: '学习记录' },
    children: [
      {
        path: 'index',
        component: () => import('@/views/home/studyNote.vue')
      }]
  },
  {
    path: '/exercise',
    name: 'ExerciseStatistics',
    redirect: '/exercise/index',
    component: Layout,
    meta: { title: '统计分析-训练统计' },
    children: [
      {
        path: 'index',
        meta: { stu: 0 },
        component: () => import('@/views/statisticAnalysis/exercise.vue')
      }]
  },
  {
    path: '/systemSet',
    name: 'SystemSet',
    redirect: '/systemSet/journalism',
    component: Layout,
    meta: { title: '系统设置' },
    children: [
      {
        path: 'journalism', // 新闻
        meta: { stu: 0, teacher: 0 }, // stu为0学生禁止访问,teacher为0教师禁止访问
        component: () => import('@/views/systemSet/journalism')
      }]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  console.log('********', from)
  const stu = to.meta.stu
  const teacher = to.meta.teacher
  const userRole = store.getters.userInfo.role
  if (stu === 0 && userRole === '2' || teacher === 0 && userRole === '1') {
    Vue.prototype.$message({ message: '您没有权限访问该页面！', type: 'warning' })
    return next(from.path)
  }
  // 否则继续导航
  next()
})

export default router
