let IP, PORT
if (process.env.NODE_ENV === 'development') {
  // 开发环境
  IP = '192.168.4.76'
  PORT = '8099'
} else {
  // 生产环境
  IP = document.location.hostname
  PORT = '8088'
}
// 本地
// const config = {
//   VUE_APP_BASE_API: `http://${IP}:${PORT}/`,
//   VUE_APP_SOCKET_URL: `ws://${IP}:${PORT}/`,
//   VUE_APP_RESOURCE_URL: `http://${IP}/resource/`,
//   VUE_APP_WEBGL_URL: `http://${IP}:8033/`
// }

// 测试环境
// const config = {
//   VUE_APP_BASE_API: `http://192.168.4.166:8099/`,
//   VUE_APP_SOCKET_URL: `ws://192.168.4.166:8099/`,
//   VUE_APP_RESOURCE_URL: `http://192.168.4.166/resourceTest`,
//   VUE_APP_WEBGL_URL: `http://192.168.4.166:8033/`
// }

// 阿里云 https
const config = {
  VUE_APP_BASE_API: `https://${IP}/api/`,
  VUE_APP_SOCKET_URL: `ws://${IP}:${PORT}/`,
  VUE_APP_RESOURCE_URL: `https://${IP}/resourcePrd/`,
  VUE_APP_WEBGL_URL: `https://${IP}/resourcePrd/webgl/`
}
export default config

